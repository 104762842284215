<template>
  <div>
    <b-modal
      id="comment-modal"
      cancel-variant="outline-secondary"
      :cancel-title="$t('button.close')"
      centered
      no-close-on-backdrop
      hide-footer
      size="xl"
    >
      <n-table
        ref="table"
        :fields="fields"
        :items="items"
        :busy="loading"
        :current-page="query.page"
        :per-page="query.limit"
        :total="total"
        :resource="resource"
        :route="route"
      >
        <template #cell(note)="data">
          {{ $t(`vehicleLog.${data.item.note}`) }}
        </template>
        <template #cell(actions)="data">
          <b-button
            v-b-tooltip.hover
            :title="$t('button.view')"
            :variant="!data.item.logData ? 'secondary' : 'primary'"
            pill
            size="sm"
            :disabled="!data.item.logData"
            @click="viewData(data.item)"
          >
            <feather-icon icon="EyeIcon" />
          </b-button>
        </template>
      </n-table>
      <n-pagination
        ref="pagination"
        :total="total"
        :per-page="query.limit"
        :page="query.page"
        @change="list"
      ></n-pagination>
    </b-modal>
    <data-history-modal ref="dataHistoryModal" />
  </div>
</template>

<script>
import { BModal, BRow, BCol, BButton } from "bootstrap-vue";
import Repository from "@/repositories/RepositoryFactory";
import NTable from "@/components/NTable";
import TableFields from "./tableFields";
import NPagination from "@/components/NPagination";
import DataHistoryModal from "./DataHistoryModal.vue";

const VehicleLogRepository = Repository.get("vehicleLog");

export default {
  components: {
    BModal,
    BRow,
    BCol,
    BButton,

    NTable,
    NPagination,
    DataHistoryModal,
  },
  computed: {
    perPage: {
      get() {
        return this.$store.state.pagination.perPage;
      },
      set(value) {
        this.$store.commit("pagination/SET_PER_PAGE", value);
      },
    },
  },
  watch: {
    "data.type": function (value) {
      this.updateTitle(value);
      return;
    },
  },
  data() {
    return {
      loading: false,
      items: [],
      total: 0,
      query: {
        page: 1,
        limit: 10,
      },
      id: null,
    };
  },
  methods: {
    viewData(item) {
      let logData = JSON.parse(item.logData);
      this.$refs.dataHistoryModal.show(logData);
    },
    show(item) {
      this.id = item.id;
      this.items = [];
      this.total = 0;
      this.query.page = 1;
      this.getData();

      this.$bvModal.show("comment-modal");
    },
    hide() {
      this.$bvModal.hide("comment-modal");
    },
    list(page = 1) {
      this.query.page = page;
      this.getData();
    },
    getData() {
      if (!this.id) {
        return;
      }

      this.loading = true;
      const params = {
        ...this.query,
        searchFields: [],
        query: [
          {
            searchby: "vehicle_id",
            searchoperator: "=",
            search: this.id,
          },
        ],
      };
      VehicleLogRepository.index(params)
        .then((response) => {
          const data = response?.data?.data;
          if (data) {
            this.items = [...data.list];
            this.total = data.total;
          }
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
  },
  setup() {
    const fields = [...TableFields];
    const resource = "vehicle";
    const route = "vehicle";

    return {
      fields,
      resource,
      route,
    };
  },
};
</script>