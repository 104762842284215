var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('load-profile'),_c('main-tab'),_c('b-card',{staticClass:"mb-2"},[(_vm.isUpdate)?_c('b-row',{staticClass:"mb-2"},[_c('b-col',{staticClass:"text-right",attrs:{"md":"12"}},[_c('n-button-loading',{attrs:{"variant":_vm.$can('update', _vm.resource) ? 'success' : 'secondary',"loading":_vm.loading,"disabled":!_vm.$can('update', _vm.resource)},on:{"submit":_vm.submit}},[_vm._v(" "+_vm._s(_vm.$t("button.save"))+" ")])],1)],1):_c('b-row',{staticClass:"mb-2"},[_c('b-col',{attrs:{"md":"6"}},[_c('b-button',{attrs:{"variant":"primary"},on:{"click":_vm.back}},[_vm._v(" "+_vm._s(_vm.$t("button.back"))+" ")])],1),_c('b-col',{staticClass:"text-right",attrs:{"md":"6"}},[_c('b-button',{attrs:{"variant":_vm.$can('update', _vm.resource) ? 'success' : 'secondary',"disabled":!_vm.$can('updaste', _vm.resource) || _vm.loading},on:{"click":function($event){_vm.isUpdate = true}}},[_vm._v(" "+_vm._s(_vm.$t("button.update"))+" ")]),(_vm.$can('read', 'vehicle-log') || _vm.loading)?_c('b-button',{staticClass:"ml-1",attrs:{"variant":"secondary"},on:{"click":function($event){return _vm.showLogModal()}}},[_vm._v(" "+_vm._s(_vm.$t("field.log"))+" ")]):_vm._e()],1)],1),_c('validation-observer',{ref:"editForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('n-form-confirmation',{key:"editForm",ref:"confirmForm",attrs:{"ok-variant":"success","form":_vm.$refs.editForm,"disabled":invalid},on:{"submit":_vm.save}},[_c('n-input',{key:("input-" + _vm.key),attrs:{"fields":_vm.fields,"initValue":_vm.initData,"disabled":!_vm.isUpdate},scopedSlots:_vm._u([{key:"zoneId",fn:function(item){return [_c('b-form-group',{attrs:{"label-for":item.field.key}},[_c('validation-provider',{attrs:{"vid":item.field.key,"name":item.field.label,"rules":item.field.rules},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',[_vm._v(" "+_vm._s(_vm.$t(item.field.label))+" ")]),_c('n-async-single-select',{ref:item.field.key,class:errors.length > 0 ? 'is-invalid' : null,attrs:{"query":[
                  {
                    searchby: 'location_id',
                    searchoperator: '=',
                    search: Number(_vm.data.locationId),
                  } ],"readonly":!_vm.isUpdate,"name":item.field.key,"repository":item.field.repository,"selection-key":item.field.selectionKey,"selection-label":item.field.selectionLabel,"clearable":item.field.clearable},model:{value:(_vm.data[item.field.key]),callback:function ($$v) {_vm.$set(_vm.data, item.field.key, $$v)},expression:"data[item.field.key]"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)]}}],null,true),model:{value:(_vm.data),callback:function ($$v) {_vm.data=$$v},expression:"data"}})],1)]}}])})],1),_c('log-modal',{ref:"logModal"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }