export default [
  {
    key: 'arrivalDate',
    label: 'field.arrivalDate',
    type: 'date-picker',
    hideOptional: true,
  },
  {
    key: 'carKey',
    label: 'field.carKey',
    type: 'text',
    hideOptional: true,
  },
  {
    key: 'vehicleId',
    label: 'field.lot',
    type: 'text',
    disabled: true,
    hideOptional: true,
  },
  {
    key: 'checkinAt',
    label: 'field.checkinDate',
    type: 'date-picker',
    hideOptional: true,
  },
  {
    key: 'vinNumber',
    label: 'field.vinNumber',
    // rules: 'required|max:100',
    rules: 'max:100',
    type: 'text',
    hideOptional: true,
  },
  {
    key: 'inspectBy',
    label: 'field.inspector',
    type: 'asyn-single-selection',
    repository: 'inspector',
    selectionKey: 'id',
    selectionLabel: 'name',
    hideOptional: true,
  },
  {
    key: 'engineNumber',
    label: 'field.engineNumber',
    // rules: 'required|max:100',
    rules: 'max:100',
    type: 'text',
    hideOptional: true,
  },
  {
    key: 'inspectAt',
    label: 'field.inspectedAt',
    type: 'date-picker',
    disabled: false,
    hideOptional: true,
  },
  {
    key: 'year',
    label: 'field.year',
    // rules: 'required',
    type: 'year-picker',
    disableType: 'after',
    hideOptional: true,
  },
  {
    key: 'sellTarget',
    label: 'field.channel',
    type: 'single-selection',
    selectionKey: 'value',
    selectionLabel: 'label',
    options: [
      {
        value: "b2b",
        label: "B2B"
      },
      {
        value: "b2c",
        label: "B2C"
      },
    ],
    hideOptional: true,
  },
  {
    key: 'brandId',
    label: 'field.brand',
    // rules: 'required',
    type: 'asyn-single-selection',
    repository: 'vehicleBrand',
    selectionKey: 'id',
    selectionLabel: 'name',
    hideOptional: true,
  },
  {
    key: 'locationId',
    label: 'field.location',
    type: 'asyn-single-selection',
    // rules: '',
    repository: 'location',
    selectionKey: 'id',
    selectionLabel: 'name',
    hideOptional: true,
  },
  {
    key: 'modelId',
    label: 'field.model',
    // rules: 'required',
    type: 'asyn-single-selection',
    repository: 'vehicleModel',
    selectionKey: 'id',
    selectionLabel: 'name',
    hideOptional: true,
  },
  {
    key: 'zoneId',
    label: 'field.zone',
    type: 'asyn-single-selection',
    repository: 'zone',
    selectionKey: 'id',
    selectionLabel: 'name',
    hideOptional: true,
  },
  {
    key: 'variant',
    label: 'field.variant',
    rules: 'max:100',
    type: 'text',
    hideOptional: true,
  },
  {
    key: 'auctionDate',
    label: 'field.auctionDate',
    type: 'date-picker',
    disabled: true,
    hideOptional: true,
  },
  {
    key: 'engineCapacity',
    label: 'field.engineCapacity',
    rules: 'max:100',
    type: 'text',
    hideOptional: true,
  },
  {
    key: 'price',
    label: 'field.reservePrice',
    type: 'currency',
    disabled: true,
    hideOptional: true,
  },
  {
    key: 'transmission',
    label: 'field.transmission',
    rules: '',
    type: 'single-selection',
    selectionKey: 'value',
    selectionLabel: 'text',
    options: [
      { text: 'AT', value: 'AT' },
      { text: 'MT', value: 'MT' },
    ],
  },
  {
    key: 'buyNowPrice',
    label: 'field.buyNowPrice',
    type: 'currency',
    disabled: true,
    hideOptional: true,
  },
  {
    key: 'mileage',
    label: 'field.mileage',
    rules: 'max:100',
    type: 'text',
    hideOptional: true,
  },
  {
    key: 'soldPrice',
    label: 'field.finalPrice',
    type: 'currency',
    hideOptional: true,
  },
  {
    key: 'colorId',
    label: 'field.color',
    rules: '',
    type: 'asyn-single-selection',
    repository: 'vehicleColor',
    selectionKey: 'id',
    selectionLabel: 'name',
    clearable: true,
    hideOptional: true,
  },
  {
    key: 'revisedPrice',
    label: 'field.revisedPrice',
    type: 'currency',
    disabled: true,
    hideOptional: true,
  },
  {
    key: 'drivetrain',
    label: 'field.drivetrain',
    type: 'single-selection',
    selectionKey: 'value',
    selectionLabel: 'text',
    options: [
      { text: 'AWD', value: 'AWD' },
      { text: '4WD', value: '4WD' },
      { text: 'FWD', value: 'FWD' },
      { text: 'RWD', value: 'RWD' },
    ],
    hideOptional: true,
  },
  {
    key: 'bd',
    label: 'field.bdPic',
    type: 'text',
    disabled: true,
    hideOptional: true,
  },
  {
    key: 'bidBy',
    label: 'field.dealer',
    type: 'asyn-single-selection',
    repository: 'dealer',
    selectionKey: 'id',
    selectionLabel: 'companyName',
    hideOptional: true,
  },
  {
    key: 'checkoutAt',
    label: 'field.checkoutDate',
    type: 'date-picker',
    hideOptional: true,
  },
  {
    key: 'seatNumber',
    label: 'field.seatNumber',
    type: 'currency',
    hideOptional: true,
  },
  {
    key: 'updatedByName',
    label: 'field.updatedBy',
    type: 'text',
    disabled: true,
    hideOptional: true,
  },
  {
    key: 'fuelTypeId',
    label: 'field.fuelType',
    type: 'asyn-single-selection',
    repository: 'vehicleFuelType',
    selectionKey: 'id',
    selectionLabel: 'name',
    hideOptional: true,
  },
  {
    key: 'status',
    label: 'field.status',
    // rules: 'required',
    type: 'single-selection',
    selectionKey: 'value',
    selectionLabel: 'text',
    translatable: true,
    options: [
      { text: 'status.1', value: '1' },
      { text: 'status.2', value: '2' },
      { text: 'status.3', value: '3' },
      { text: 'status.4', value: '4' },
      { text: 'status.5', value: '5' },
      { text: 'status.6', value: '6' },
      { text: 'status.7', value: '7' },
      { text: 'status.8', value: '8' },
      { text: 'status.9', value: '9' },
      { text: 'status.10', value: '10' },
      { text: 'status.11', value: '11' },
      { text: 'status.12', value: '12' },
      { text: 'status.13', value: '13' },
      { text: 'status.14', value: '14' },
      { text: 'status.15', value: '15' },
      { text: 'status.16', value: '16' },
      { text: 'status.17', value: '17' },
    ],
    hideOptional: true,
  },
  {
    key: 'note',
    label: 'field.remark',
    rules: 'max:200',
    type: 'textarea',
    cols: 12,
    hideOptional: true,
  },
]
