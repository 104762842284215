<template>
  <b-modal id="modal-data-history" cancel-variant="outline-secondary" body-class="p-0" centered hide-footer size="xl"
    :title="$t(`field.detail`)">
    <b-card class="image-card mb-0" v-if="data.id">
      <div class="d-none d-md-block">
        <b-table-simple responsive borderless striped class="table-information mb-0">
          <b-tbody>
            <b-tr>
              <b-th> {{ $t("field.brand") }}: </b-th>
              <b-td> {{ data.brandName }} </b-td>
              <b-th> {{ $t("field.model") }}: </b-th>
              <b-td> {{ data.model }} </b-td>
              <b-th> {{ $t("field.year") }}: </b-th>
              <b-td> {{ data.year }} </b-td>
            </b-tr>
            <b-tr>
              <b-th> {{ $t("field.color") }}: </b-th>
              <b-td> {{ data.colorName }} </b-td>
              <b-th> {{ $t("field.vinNumber") }}: </b-th>
              <b-td> {{ data.vinNumber }} </b-td>
              <b-th> {{ $t("field.engineNumber") }}: </b-th>
              <b-td> {{ data.engineNumber }} </b-td>
            </b-tr>
            <b-tr>
              <b-th> {{ $t("field.engineCapacity") }}: </b-th>
              <b-td> {{ data.engineCapacity }} </b-td>
              <b-th> {{ $t("field.transmission") }}: </b-th>
              <b-td> {{ data.transmission }} </b-td>
              <b-th> {{ $t("field.fuelType") }}: </b-th>
              <b-td> {{ data.fuelType }} </b-td>
            </b-tr>
            <b-tr>
              <b-th> {{ $t("field.variant") }}: </b-th>
              <b-td> {{ data.variant }} </b-td>
              <b-th> {{ $t("field.seatNumber") }}: </b-th>
              <b-td> {{ data.seatNumber }} </b-td>
              <b-th> {{ $t("field.arrivalDate") }}: </b-th>
              <b-td> {{ data.arrivalDate | formatDate("LL") }} </b-td>
            </b-tr>
            <b-tr>
              <b-th> {{ $t("field.note") }}: </b-th>
              <b-td colspan="5"> {{ data.note }} </b-td>
            </b-tr>
          </b-tbody>
        </b-table-simple>
      </div>
      <div class="d-md-none">
        <b-table-simple responsive borderless striped class="table-information mb-0">
          <b-tbody>
            <b-tr>
              <b-th> {{ $t("field.brand") }}: </b-th>
              <b-td> {{ data.brandName }} </b-td>
            </b-tr>
            <b-tr>
              <b-th> {{ $t("field.model") }}: </b-th>
              <b-td> {{ data.model }} </b-td>
            </b-tr>
            <b-tr>
              <b-th> {{ $t("field.year") }}: </b-th>
              <b-td> {{ data.year }} </b-td>
            </b-tr>
            <b-tr>
              <b-th> {{ $t("field.color") }}: </b-th>
              <b-td> {{ data.colorName }} </b-td>
            </b-tr>
            <b-tr>
              <b-th> {{ $t("field.vinNumber") }}: </b-th>
              <b-td> {{ data.vinNumber }} </b-td>
            </b-tr>
            <b-tr>
              <b-th> {{ $t("field.engineNumber") }}: </b-th>
              <b-td> {{ data.engineNumber }} </b-td>
            </b-tr>
            <b-tr>
              <b-th> {{ $t("field.engineCapacity") }}: </b-th>
              <b-td> {{ data.engineCapacity }} </b-td>
            </b-tr>
            <b-tr>
              <b-th> {{ $t("field.transmission") }}: </b-th>
              <b-td> {{ data.transmission }} </b-td>
            </b-tr>
            <b-tr>
              <b-th> {{ $t("field.fuelType") }}: </b-th>
              <b-td> {{ data.fuelType }} </b-td>
            </b-tr>
            <b-tr>
              <b-th> {{ $t("field.variant") }}: </b-th>
              <b-td> {{ data.variant }} </b-td>
            </b-tr>
            <b-tr>
              <b-th> {{ $t("field.seatNumber") }}: </b-th>
              <b-td> {{ data.seatNumber }} </b-td>
            </b-tr>
            <b-tr>
              <b-th> {{ $t("field.arrivalDate") }}: </b-th>
              <b-td> {{ data.arrivalDate | formatDate("LL") }} </b-td>
            </b-tr>
            <b-tr>
              <b-th> {{ $t("field.note") }}: </b-th>
              <b-td> {{ data.note }} </b-td>
            </b-tr>
          </b-tbody>
        </b-table-simple>
      </div>

      <div class="d-flex mb-1 mt-2" v-if="data.documents.length">
        <i class="icon las la-folder-open"></i>
        <h4 class="ml-50">{{ $t("field.documents") }}</h4>
      </div>

    </b-card>
  </b-modal>
</template>

<script>
  import {
    BModal,
    BTableSimple,
    BTbody,
    BTr,
    BTh,
    BTd,
    BRow,
    BCol,
    BImg,
    BCard,
  } from "bootstrap-vue";

  export default {
    components: {
      BModal,
      BTableSimple,
      BTbody,
      BTr,
      BTh,
      BTd,
      BRow,
      BCol,
      BImg,
      BCard,
    },
    data() {
      return {
        loading: false,
        data: {},
      };
    },
    methods: {
      show(data) {
        this.data = {
          ...data,
        };

        this.$bvModal.show("modal-data-history");
      },
      hide() {
        this.$bvModal.hide("modal-data-history");
      },
      viewFile(document) {
        if (document.fileType.startsWith("image/")) {
          this.$imagePreview({
            initIndex: 0,
            images: [document.fileUrl],
            zIndex: 9999,
            isEnableDownloadImage: false,
            isEnableImagePageIndicator: false,
            isEnableBlurBackground: true,
            onClose: () => { },
          });
        } else {
          window.open(document.fileUrl, "_blank").focus();
        }
      },
    },
  };
</script>